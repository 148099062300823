<template>
    <div>
        <div v-if="authUserPermission['table-column-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('table_column') }}</h3>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_table_column')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['table-column-create']">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('code')" :prop="tt('code')" sortable>
                                <template v-slot="{row}">
                                    {{row.table_column_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('table')" :prop="tt('table')" sortable>
                                <template v-slot="{row}">
                                    {{row.table}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('column')" :prop="tt('column')" sortable>
                                <template v-slot="{row}">
                                    {{row.column}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['table-column-update'] || authUserPermission['table-column-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['table-column-update']">Edit</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['table-column-delete']">Delete</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('code')" :placeholder="tt('code')" v-model="tableColumn.table_column_code" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                        <div class="row">
                            <el-autocomplete
                            class="inline-input col-md-12"
                            v-model="dataRelation.id"
                            :fetch-suggestions="querySearch"
                            :placeholder="tt('choose_table')"
                            @select="handleSelect"
                            size="large"
                            ></el-autocomplete>
                        </div>
                        <br>

                        <label class="form-control-label">{{ tt('column') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('column')" rules="required">
                            <el-select class="select-danger" v-model="tableColumn.column" :placeholder="tt('choose_column')" :disabled="!columnData.length">
                                <el-option class="select-danger" :value="cd['column_name']" :label="cd['column_name']" :key="cd['column_name']" v-for="cd in columnData"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import tableColumn from '@/services/setting/tableColumn.service';

    export default {        
        data() {
            return {                   
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },           
                form: {
                    add: true,
                    title: this.tt('add_table_column'),
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,   
                columnData: [],
                tableData: [],
                links: [],
                dataRelation: {},
                tableColumn: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get();
            // console.log(this.columnData)
            this.links = this.tableData;
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, tableColumn.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data; 
                    context.dataRelation.table = 
                    response.data.data.tables.forEach(function(item, index) {
                        context.tableData[index] = {
                            'code': item['table_name'],
                            'value': item['table_name']
                        };
                    });
                    context.onLoad = false;  
                    // console.log(response.data.data.tables)        
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            getColumnList(id) {
                let context = this;               
                let params = context.dataRelation.id;
                // alert(id)

                Api(context, tableColumn.column_list(id)).onSuccess(function(response) {    
                    // context.tableColumn = response.data.data.data.data;
                    // console.log('iki')
                    context.columnData = response.data.data;
                    // console.log(context.columnData)
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.columnData = [];
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_table_column');
                this.form.show = true;
                this.tableColumn = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, tableColumn.show(id)).onSuccess(function(response) {
                    context.tableColumn = response.data.data;
                    context.dataRelation.id = response.data.data.table;
                    context.getColumnList(response.data.data.table);
                    context.form.add = false;
                    context.form.title = context.tt('edit_table_column');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, tableColumn.create(this.tableColumn));
                } else {{
                    api = Api(context, tableColumn.update(this.tableColumn.id, this.tableColumn));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, tableColumn.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            querySearch(queryString, cb) {
                var links = this.links;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                // call callback function to return suggestions
                cb(results);
            },
            createFilter(queryString) {
                return (link) => {
                  return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            loadAll(table) {
                return table;
            },
            handleSelect(item) {
                // console.log(item);
                this.tableColumn.table = item.code;
                this.getColumnList(item.code);
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
